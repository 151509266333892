(function () {
	'use strict';
	angular
		.module('app')
		.controller('AccountSettingsCtrl', [
			'$scope',
			'$rootScope',
			'utilities',
			'accountSettings',
			'firebaseIO',
			'seedcodeCalendar',
			AccountSettingsCtrl,
		]);

	let groupBackup;

	function AccountSettingsCtrl(
		$scope,
		$rootScope,
		utilities,
		accountSettings,
		firebaseIO,
		seedcodeCalendar
	) {
		$scope.$on('groupName', ($event, groupName) => {
			$scope.group.name = groupName;
		});

		$scope.isLoading = true;

		firebaseIO.getGroupData('group', (result) => {
			$scope.$evalAsync(() => {
				$scope.group = result;
				groupBackup = {...result};
				$scope.isLoading = false;
				$rootScope.$broadcast('groupName', result.name);
			});
		});

		$scope.groupSettingsPopover = accountSettings.groupSettingsPopover;

		$scope.help = utilities.help;

		$scope.close = close;

		$scope.updateGroupName = updateGroupName;

		/** @type {() => void} */
		function close() {
			const callback = $scope.popover?.config?.callback;
			$scope.popover.config.show = false;
			$scope.group = {...groupBackup};
			if (callback) {
				callback(null);
			}
		}

		/** @type {(name: string) => void} */
		function updateGroupName(name) {
			const callback = $scope.popover?.config?.callback;
			firebaseIO.setGroupData('group', 'name', name, false, runCallback);
			$scope.$evalAsync(() => {
				$scope.group.name = name;
			});
			groupBackup.name = name;
			$scope.popover.config.show = false;
			function runCallback(groupName) {
				// Result is saved data (group name)
				const config = seedcodeCalendar.get('config');
				config.groupName = name;
				$rootScope.$broadcast('groupName', name);
				if (callback) {
					callback(groupName);
				}
			}
		}
	}
})();
